<template>
  <div class="adoption-page">
    <xmas-banner v-if="$store.getters.featureSwitchesState.XMAS_DISCOUNT"></xmas-banner>
    <cny-banner v-if="$store.getters.featureSwitchesState.HOLIDAY24_SALE"></cny-banner>
    <header class="text_only">
      <h1>Trace Your Birth Family</h1>
      <p>
        We help you discover the story of your adoption and search for biological and foster families in Greater China
        area.
      </p>
    </header>

    <div class="readable_content image_panel_interlude" id="found-featured">
      <h5>As featured in the Netflix documentary, <i>Found</i> !</h5>
      <img :src="$getAsset('/assets/services/adoption/found_poster.jpg')" height="600" width="400" />
    </div>

    <div class="image_panel reverse_panel" id="what_we_can_do">
      <div class="image_panel_text">
        <h4>What We Can Do</h4>
        <p>
          A comprehensive search includes all four steps below, which we typically suggest taking in the listed order.
          However, you are free to select any service in isolation, or to start with one and then move on to another
          depending on the outcome.
        </p>
        <ul>
          <li>
            Facilitate DNA testing: yours as well as potential birth family targets.
            <mcr-wiki-glossary-link
              natural-id="facilitate-dna-testing-yours-as-well-as-potential-birth-family-targets"
              text="Details & Pricing"
            ></mcr-wiki-glossary-link>
          </li>
          <li>
            Ad placement on social & traditional media.
            <mcr-wiki-glossary-link
              natural-id="ad-placement-on-social-traditional-media"
              text="Details & Pricing"
            ></mcr-wiki-glossary-link>
          </li>
          <li>
            Identifying & contacting relevant institutions.
            <mcr-wiki-glossary-link
              natural-id="identifying-contacting-relevant-institutions"
              text="Details & Pricing"
            ></mcr-wiki-glossary-link>
          </li>
          <li>
            Visiting your birthplace.
            <mcr-wiki-glossary-link
              natural-id="visiting-your-birthplace"
              text="Details & Pricing"
            ></mcr-wiki-glossary-link>
          </li>
        </ul>
      </div>
      <div class="image_panel_image">
        <img :src="$getAsset('/assets/services/adoption/newsad.jpg')" />
      </div>
    </div>

    <div class="readable_content image_panel_interlude">
      <h4>What We Need</h4>
      <ul>
        <li>Relevant documentation such as adoptee booklet, photos, new articles, and police reports</li>
        <li>Contact information of key individuals in Chinese characters</li>
        <li>Addresses of the orphanage and/or hospital in Chinese characters</li>
      </ul>
    </div>

    <div class="image_panel reverse_panel">
      <div class="image_panel_text">
        <h4>What To Expect</h4>
        <ul>
          <li><strong>Duration:</strong> Cases typically last 2-10 months</li>
          <li><strong>Support:</strong> Dedicated My China Roots collaborator</li>
          <li><strong>Progress:</strong> Email updates every month</li>
          <li><strong>Deliverables:</strong> Documentation of research and findings</li>
        </ul>
      </div>
      <div class="image_panel_image">
        <img :src="$getAsset('/assets/services/adoption/LZ2_5477_Adoption-Researcher.jpg')" />
      </div>
    </div>

    <div class="readable_content image_panel_interlude">
      <h4>Frequently Asked Questions</h4>
      <ul class="desc">
        <li>
          <mcr-wiki-glossary-link naturalId="can-you-find-my-birth-parents" text="Can you find my birth parents?" />
        </li>
        <li><mcr-wiki-glossary-link naturalId="what-can-you-find" text="What will you be looking for?" /></li>
        <li>
          <mcr-wiki-glossary-link
            naturalId="is-dna-testing-popular-in-china-is-it-likely-that-a-biological-family-member-has-done-a-dna-test-in-china"
            text="Is DNA testing popular in China? How likely is it that a biological family member has done a DNA test in China?"
          />
        </li>
        <li>
          <mcr-wiki-glossary-link
            naturalId="do-you-provide-dna-test-and-services"
            text="Do you provide DNA test and services?"
          />
        </li>
        <li>
          <mcr-wiki-glossary-link
            naturalId="can-i-be-refunded-if-my-china-roots-cannot-find-anything-in-the-end"
            text="Can I be refunded if My China Roots cannot find anything in the end?"
          />
        </li>
        <li>
          <mcr-wiki-glossary-link
            naturalId="if-id-be-interested-to-hire-my-china-roots-what-would-the-next-steps-be"
            text="If I am interested in hiring My China Roots, what would our next steps be?"
          />
        </li>
        <li>
          <mcr-wiki-glossary-link
            naturalId="what-historic-documents-could-i-look-for-to-facilitate-eventual-research"
            text="What historical documents could I look for to prepare for research with My China Roots?"
          />
        </li>
        <li>
          <mcr-wiki-glossary-link
            naturalId="how-do-i-know-if-im-ready-to-search"
            text="How do I know if I am ready to search?"
          />
        </li>
        <li>
          <mcr-wiki-glossary-link
            naturalId="what-if-i-change-my-mind-about-tracing-my-birth-parents-after-youve-already-started"
            text="What if I change my mind about tracing my birth parents, after you’ve already started searching?"
          />
        </li>
        <li>
          <mcr-wiki-glossary-link
            naturalId="will-my-birth-parents-speak-english"
            text="Will my birth parents and relatives speak English?"
          />
        </li>
        <li>
          <mcr-wiki-glossary-link
            naturalId="how-do-chinese-society-view-international-adoption"
            text="How do Chinese government and society view international adoption and finding birth parents at large?"
          />
        </li>
      </ul>
    </div>

    <hr />

    <div class="single-testimonial readable_content" id="testimonial">
      <img
        :data-src="$getAsset('/assets/services/adoption/Anne-Ruizendaal-1.jpg')"
        class="lazyload"
        width="800"
        height="450"
      />
      <span class="source"><strong>Anne R.</strong>, Netherlands</span>
      <p>
        <span class="quote-symbol">&ldquo;</span>Thanks to your integrity and translation, we really felt no barrier
        existed between them and us. During our visits, we learned that Mila was literally saved by her truly
        exceptional foster mother, who gave Mila all the love and care she needed. You made at least two families very
        happy by reuniting us using your valuable skills and professionalism!
      </p>
    </div>
    <prospect-request-end-actions :book-call-url="bookCallUrl"
      >Trace your birth family today.</prospect-request-end-actions
    >
  </div>
</template>

<script>
import cnyBanner from '@/components/common/promotions/cnyBanner';
import xmasBanner from '@/components/common/promotions/xmasBanner';
import ProspectRequestEndActions from '@/components/common/prospectRequestEndActions';

const mcrWikiGlossaryLink = () => import('@common/elements/glossary/mcrWikiGlossaryLink');

export default {
  metaInfo: {
    title: 'Adoption Research',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: 'We search for adoptees’ birth and foster families in China, Hong Kong, Macau, or Taiwan.',
      },
    ],
  },
  computed: {
    bookCallUrl() {
      return process.env.VUE_APP_CALENDLY_ADOPTION_URL;
    },
  },
  components: {xmasBanner, cnyBanner, ProspectRequestEndActions, mcrWikiGlossaryLink},
};
</script>

<style scoped lang="scss">
@import '@common/style/mixins';
@import '~@/components/common/styles/services.scss';

#found-featured {
  text-align: center;
  img {
    margin-top: 10px;
  }
}
hr {
  max-width: $reading-max-width;
}
#testimonial {
  img {
    margin-top: 20px;
    border-radius: 0;
  }
}

.image_panel .image_panel_text {
  h1,
  h4 {
    padding-bottom: 20px;
    border-bottom: 1px solid $divider-line-color;
  }
}

ul {
  margin-bottom: 0;
  li:last-child {
    padding-bottom: 0;
  }
}

.image_panel,
.readable_content {
  padding-top: 20px;
  padding-bottom: 50px;
}
</style>
