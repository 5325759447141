<template>
  <a href="mailto:request@mychinaroots.com" @click="click">request@mychinaroots.com</a>
</template>

<script>
import AnalyticsHandler from '@common/utils/analytics/analytics.handler';

export default {
  methods: {
    click() {
      AnalyticsHandler.trackEmailMCRLinkClickEvent();
    },
  },
};
</script>

<style scoped></style>
