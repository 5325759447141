<template>
  <mcr-button class="book-call-button" :class="classes" @click="bookCall">{{ label }}</mcr-button>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import AnalyticsMainHandler from '@common/utils/analytics/analytics.main';
import {getRoutePageName} from '@common/utils/analytics/utils.analytics';
import {initCalendlyScript} from '@common/utils/script.loader';

export default {
  created() {
    initCalendlyScript();
    window.addEventListener('message', this.calendlyMessageHandler);
  },
  destroyed() {
    window.removeEventListener('message', this.calendlyMessageHandler);
  },
  props: {
    url: {type: String, default: ''},
    redirectRoute: {type: Object},
    redirectOnSchedule: {type: Boolean, default: true},
    label: {type: String, default: "Let's Talk"},
    color: {type: String, validator: value => ['red', 'tan'].includes(value), default: 'tan'},
  },
  computed: {
    classes() {
      return [this.color];
    },
  },
  methods: {
    bookCall() {
      const prefill = this.$store.getters.userIsLoggedInState
        ? {
            name: this.$store.getters.userFullNameState,
            email: this.$store.getters.userEmailState,
          }
        : {};
      Calendly.initPopupWidget({url: this.url || process.env.VUE_APP_CALENDLY_CEO_URL, prefill});
      AnalyticsMainHandler.trackClickContactButton(this.label, false, getRoutePageName(this.$route), true);
    },
    calendlyMessageHandler(e) {
      if (this.redirectOnSchedule && e.data.event && e.data.event === 'calendly.event_scheduled') {
        this.$router.push(this.redirectRoute || {name: 'main-page'});
      }
    },
  },
  components: {McrButton},
};
</script>

<style lang="scss" scoped>
.mcr-button.book-call-button.tan {
  background-color: $mcr-dark-tan;

  &:hover {
    background-color: $mcr-tan;
  }
}
</style>
