<template>
  <div class="institutions-indexing-page">
    <header class="full_image">
      <h1>Digitization Partnerships</h1>
      <p>Work with us to preserve the Chinese historical records of your organization.</p>
      <div class="header_actions">
        <mcr-buttons-row>
          <book-call-button
            :url="calendlyUrl"
            label="Let's Talk"
            class="fat"
            color="red"
            :redirect-on-schedule="false"
          ></book-call-button>
        </mcr-buttons-row>
      </div>
      <div class="logo_wall">
        <img
          :data-src="$getAsset('/assets/services/logo_wall/heshan-museum.png')"
          class="lazyload heshan-logo"
          height="56"
          width="168"
        />
      </div>

      <img
        :data-src="$getAsset('/assets/services/translation/Knolling-Records-web-2.jpg')"
        class="lazyload"
        width="1805"
        height="1128"
      />
    </header>

    <div class="readable_content">
      <h4>What We Digitize</h4>
      <p>
        Does your organization have records of Chinese communities? Get in touch if you have archives of the Overseas
        Chinese community, including:
      </p>
      <ul>
        <li>Association records</li>
        <li>Passenger lists and immigration records</li>
        <li>Burial records</li>
        <li>Newspaper clippings</li>
        <li>Photographs</li>
        <li>Personal letters or remittances</li>
        <li>Business records</li>
        <li>Telephone and business directories</li>
        <li>Family tree books (zupu)</li>
        <li>Identity documents</li>
      </ul>

      <h4>Our Services</h4>
      <div class="image_panel reverse_panel">
        <div class="image_panel_text">
          <h4>Scanning & OCR</h4>
          <div class="text-block">
            <div>
              We travel on-site to convert your print or microform materials into high quality images for long-term
              preservation and high quality Optical Character Recognition (OCR).
            </div>
          </div>
        </div>
        <div class="image_panel_image">
          <img
            :data-src="$getAsset('/assets/services/scanning_indexing/scanner.jpg')"
            class="lazyload"
            width="400"
            height="300"
          />
        </div>
      </div>

      <div class="image_panel">
        <div class="image_panel_text">
          <h4>Indexing & Translation</h4>
          <div class="text-block">
            <div>
              We understand the challenges of dealing with Chinese-language materials. Work with our in-house bilingual
              Chinese-English indexers to unlock the potential of your collections.
            </div>
          </div>
        </div>
        <div class="image_panel_image">
          <img
            :data-src="$getAsset('/assets/services/village/researchers-at-work.jpg')"
            class="lazyload"
            width="400"
            height="300"
          />
        </div>
      </div>

      <div class="image_panel reverse_panel">
        <div class="image_panel_text">
          <h4>Hosting & Discovery</h4>
          <div class="text-block">
            <div>
              We’ll host your digitized content and connect your data to the thousands of other records on our database
              of Chinese ancestors.
            </div>
          </div>
        </div>
        <div class="image_panel_image">
          <img :data-src="$getAsset('/assets/services/tree/records.jpg')" class="lazyload" width="400" height="300" />
        </div>
      </div>

      <h4>Pricing</h4>
      <p>Choose between:</p>
      <ul>
        <li>
          <b>Free</b>: We conduct the entirety of the scanning and digitizing of your materials. In exchange, My China
          Roots will hold exclusive rights on the outputs of digitization for an embargo period of 5 years.
        </li>
        <li>
          <b>Paid</b>: We digitize and/or transcribe your collections for a fee, and your organization receives the
          digitized outputs upon project completion.
        </li>
      </ul>
    </div>
    <end-actions>
      <h5>Support our mission to preserve the history of the Chinese diaspora.</h5>
      <mcr-buttons-row>
        <book-call-button
          :url="calendlyUrl"
          label="Partner with Us"
          class="fat"
          color="red"
          :redirect-on-schedule="false"
        ></book-call-button>
      </mcr-buttons-row>
    </end-actions>
  </div>
</template>

<script>
import BookCallButton from '@common/elements/buttons/BookCallButton';
import endActions from '@common/elements/buttons/endActions';
import mcrButtonsRow from '@common/elements/buttons/mcrButtonsRow';

export default {
  metaInfo: {
    title: 'Digitization Partnerships',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content:
          "We work with Chinese community orgs, libraries , museums and universities to preserve their archival collections for posterity. Join us as we build the world's first digital archive for the Chinese diaspora!",
      },
    ],
  },
  data() {
    return {
      calendlyUrl: process.env.VUE_APP_CALENDLY_DB_URL,
    };
  },
  components: {BookCallButton, mcrButtonsRow, endActions},
  name: 'InstitutionsIndexingPage',
};
</script>

<style lang="scss" scoped>
.institutions-indexing-page {
  .logo_wall {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    opacity: 0.3;

    .heshan-logo {
      height: 120px;
    }

    img {
      margin: 12px 16px;
      width: auto;

      @media only screen and (max-width: $breakpoint-mobile) {
        margin: 12px 12px;
      }
    }
  }

  .readable_content {
    padding-top: 0;
  }

  .image_panel {
    padding: 20px 0 0;
    @media only screen and (min-width: $breakpoint-tablet) {
      padding: 20px 0 40px;

      .image_panel_image img {
        width: 400px;
        height: 300px;
        object-fit: cover;
      }
    }
  }
}
</style>
