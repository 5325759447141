<template>
  <div class="name-translation-page">
    <xmas-banner v-if="$store.getters.featureSwitchesState.XMAS_DISCOUNT"></xmas-banner>
    <cny-banner v-if="$store.getters.featureSwitchesState.HOLIDAY24_SALE"></cny-banner>
    <header class="text_only">
      <h1>Ancestry Research</h1>
      <p>Receive a curated photographic report of your ancestral home in China.</p>
      <div class="header_actions">
        <mcr-buttons-row>
          <mcr-button-router-link-to-pr label="Enquire"></mcr-button-router-link-to-pr>
        </mcr-buttons-row>
      </div>
    </header>
    <div class="readable_content">
      <div class="video">
        <LazyYoutubeVideo
          src="https://www.youtube.com/embed/WmRfy_dS7eU"
          :thumbnail="{jpg: $getAsset('/assets/services/village/discovery-video-thumb.jpg'), webp: ''}"
          :webp="false"
        />
      </div>
      <h4>Have you ever wondered where your family came from in China, and what's left there?</h4>
      <p>
        If you want to visit your family's hometown, but can't make the trip to China, our
        <strong class="feature-highlight">field researchers</strong> are here to help!
      </p>
      <p>
        We'll track down your hometown and preserve your village in a
        <strong class="feature-highlight">beautiful curated photographic report</strong>&mdash; perfect for sharing with
        the whole family.
      </p>
      <p>
        Our village visit doubles as an investigation, where we can uncover
        <strong class="feature-highlight">relatives</strong>, <strong class="feature-highlight">stories</strong>,
        <strong class="feature-highlight">documents</strong> and other traces of your family.
        <img
          :src="this.$getAsset('/assets/services/village/exploration-in-action.jpg')"
          width="1200"
          height="800"
          class="lazyload exploration-in-action"
        />
      </p>

      <h4>How it Works</h4>
      <div class="figures-highlight">
        <div class="breakdown-property">
          <span class="price_label">Average Price</span>
          <span class="price">US$ 2,110</span>
        </div>
        <div class="breakdown-property">
          <span class="price_label">Average Project Length</span>
          <span class="price">3 Months</span>
        </div>
      </div>
      <div class="process-walkthrough">
        <div class="label">Step-by-step</div>
        <ul>
          <li>First, we'll ask you for information about your ancestors and where they came from.</li>
          <li>We'll track down the modern location and name of the hometown.</li>
          <li>We'll reach out to the village in preparation for our visit.</li>
          <li>
            We'll send one of our roots detectives and photographers to capture the village, interview distant
            relatives, and preserve any traces left of your family.
          </li>
          <li>We'll compile our findings into a beautiful report for you.</li>
          <li>We'll jump on a call with you to present our findings and answer any questions you might have.</li>
        </ul>
      </div>
      <mcr-button-router-link-to-pr label="Enquire"></mcr-button-router-link-to-pr>
      <div class="faq">
        <div class="bold">Frequently Asked Questions</div>
        <ul class="desc">
          <li><mcr-wiki-glossary-link naturalId="howfeescalculated" text="How are fees calculated?" /></li>
          <li>
            <mcr-wiki-glossary-link
              naturalId="whatifnovillage"
              text="Will I still be charged in full if my village is no longer standing?"
            />
          </li>
          <li>
            <mcr-wiki-glossary-link
              naturalId="whatifnorecords"
              text="Will I still be charged in full if there are no records left of my family?"
            />
          </li>
        </ul>
      </div>
    </div>
    <div class="limited_content demo-report">
      <h5>Sample Report</h5>
      <div class="auto-video">
        <router-link to="/report/demo/baisha" target="_blank">
          <video
            loop
            muted
            autoplay
            :poster="$getAsset('/assets/services/ancestry/FWSBaishaDemo.jpg')"
            width="1200"
            height="666"
          >
            <source
              :src="this.$getAsset('/assets/services/ancestry/family-website-demo-baisha.mp4')"
              type="video/mp4"
            />
            <source
              :src="this.$getAsset('/assets/services/ancestry/family-website-demo-baisha.webm')"
              type="video/webm"
            />
          </video>
        </router-link>
      </div>
      <strong
        ><router-link to="/report/demo/baisha" class="demo-link" target="_blank"
          >Explore our live demo &#10142;</router-link
        ></strong
      >
    </div>
    <div class="readable_content end-section">
      <div class="single-testimonial">
        <img :src="this.$getAsset('/assets/client_pics/geraldchen.jpg')" width="154" height="153" class="lazyload" />
        <span class="source"><strong>Gerald</strong>, Canada</span>
        <p>
          <span class="quote-symbol">&ldquo;</span>Many of us feel the call of past generations and yearn for this
          connection even though separated by much time and distance.  But how to begin this journey when one does not
          know the language nor the country?  MyChinaRoots was the perfect intermediary.  I cannot speak highly enough
          of them. The research they offer is professional and comprehensive. The final report is amazing.
        </p>
      </div>
    </div>
    <prospect-request-end-actions>Walk in your ancestors footsteps.</prospect-request-end-actions>
  </div>
</template>

<script>
import McrButtonRouterLinkToPr from '@common/elements/buttons/mcrButtonRouterLinkToPr';
import mcrButtonsRow from '@common/elements/buttons/mcrButtonsRow';
import LazyYoutubeVideo from 'vue-lazy-youtube-video';

import cnyBanner from '@/components/common/promotions/cnyBanner';
import xmasBanner from '@/components/common/promotions/xmasBanner';
import ProspectRequestEndActions from '@/components/common/prospectRequestEndActions';

const mcrWikiGlossaryLink = () => import('@common/elements/glossary/mcrWikiGlossaryLink');

export default {
  metaInfo: {
    title: 'Ancestry Research',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: 'Receive a curated photographic report of your ancestral home in China.',
      },
    ],
  },
  components: {
    McrButtonRouterLinkToPr,
    LazyYoutubeVideo,
    mcrWikiGlossaryLink,
    ProspectRequestEndActions,
    mcrButtonsRow,
    xmasBanner,
    cnyBanner,
  },
};
</script>

<style scoped lang="scss">
@import '~@/components/common/styles/services.scss';

.exploration-in-action {
  box-shadow: $box-shadow;
  margin-top: 24px;
  display: block;
}

.demo-report {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0;
  @media only screen and (max-width: $breakpoint-mobile) {
    .auto-video {
      margin-bottom: 12px;
    }
  }
}

.end-section {
  border-top: 1px solid $divider-line-light;
  margin-top: 24px;
}
</style>
