<template>
  <div class="stripe-price-checkout-session-button" @click="onClick">
    <mcr-button :loading="loading">
      <slot></slot>
    </mcr-button>
    <div v-if="error" class="error">{{ error }}</div>
  </div>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import AnalyticsMainHandler from '@common/utils/analytics/analytics.main';
import {getRoutePageName} from '@common/utils/analytics/utils.analytics';

export default {
  props: {
    priceId: String,
    productName: String,
    successPath: String,
    beforeRedirect: {type: Function, required: false},
  },
  created() {
    this.$stripe.init();
  },
  data() {
    return {
      error: '',
      loading: false,
    };
  },
  methods: {
    onClick() {
      if (this.beforeRedirect) {
        this.beforeRedirect();
      }
      AnalyticsMainHandler.trackClickPaymentButton(
        this.getButtonLabel(),
        this.productName,
        getRoutePageName(this.$route)
      );
      this.redirectToCheckout();
    },
    redirectToCheckout() {
      this.error = '';
      this.loading = true;
      this.$stripe
        .redirectToCheckout({
          lineItems: [{price: this.priceId, quantity: 1}],
          mode: 'payment',
          successUrl: `${process.env.VUE_APP_WEB_SITE_BASE}${this.successPath}`, // &session_id={CHECKOUT_SESSION_ID}
          cancelUrl: window.location.href,
        })
        .then(function (result) {
          if (result.error) {
            this.error = result.error.message;
            this.loading = false;
          }
        });
    },
    getButtonLabel() {
      try {
        return this.$slots.default[0].text;
      } catch (e) {
        console.log(e);
        return '';
      }
    },
  },
  components: {McrButton},
};
</script>

<style scoped></style>
