<template>
  <div class="name-translation-page">
    <header class="text_only">
      <h1>Village Location Package</h1>
      <p>We track down the hometown of your Chinese ancestor.</p>
      <div class="header_actions">
        <mcr-buttons-row>
          <mcr-button-router-link-to-pr label="Enquire"></mcr-button-router-link-to-pr>
        </mcr-buttons-row>
      </div>
    </header>
    <div class="readable_content">
      <img
        :src="this.$getAsset('/assets/services/village/classic-village-map.jpg')"
        width="1000"
        height="466"
        class="lazyload"
      />

      <h4>Do you know where your Chinese ancestors came from, but can't locate it on a map?</h4>
      <p>Our experts are here to help!</p>
      <p>
        Many places in China have been renamed in the last 150 years, and GoogleMaps doesn't cover all locations. We'll
        investigate whether your ancestral home still exists, and find its exact address.
      </p>
      <img
        :src="this.$getAsset('/assets/services/village/researchers-at-work.jpg')"
        width="800"
        height="404"
        class="lazyload researcher-image"
      />

      <h4>How it Works</h4>
      <div class="figures-highlight">
        <div class="breakdown-property">
          <span class="price_label">Average Price</span>
          <span class="price">US$ 650</span>
        </div>
        <div class="breakdown-property">
          <span class="price_label">Average Project Length</span>
          <span class="price">3 Weeks</span>
        </div>
      </div>
      <div class="process-walkthrough">
        <div class="label">Step-by-step</div>
        <ul>
          <li>First, we'll ask you for information about your ancestors and where they came from.</li>
          <li>
            Next, we'll make phone calls to local government offices, check geographical gazetteers and other resources
            to track down the exact location of your village.
          </li>
          <li>
            Once we've found some leads, we'll contact each of them to see if they match the place your ancestor came
            from.
          </li>
          <li>
            If successful, we'll send you the confirmed location in GoogleMaps along with the contact information of
            relevant people on the ground.
          </li>
        </ul>
      </div>
      <mcr-button-router-link-to-pr label="Enquire"></mcr-button-router-link-to-pr>
      <div class="faq">
        <div class="bold">Frequently Asked Questions</div>
        <ul class="desc">
          <li><mcr-wiki-glossary-link naturalId="howfeescalculated" text="How are fees calculated?" /></li>
          <li>
            <mcr-wiki-glossary-link naturalId="whatifcantfindvillage" text="What if you can't find my village?" />
          </li>
        </ul>
      </div>
      <div class="sub-title">Sample Report</div>
      <div class="sample-report-image">
        <img
          :src="this.$getAsset('/assets/services/village/location-sample-deliverable.jpg')"
          width="800"
          height="331"
          class="lazyload"
        />
      </div>
      <div class="single-testimonial">
        <img :src="this.$getAsset('/assets/client_pics/geraldchen.jpg')" width="154" height="153" class="lazyload" />
        <span class="source"><strong>Gerald</strong>, Canada</span>
        <p>
          <span class="quote-symbol">&ldquo;</span>Many of us feel the call of past generations and yearn for this
          connection even though separated by much time and distance.  But how to begin this journey when one does not
          know the language nor the country?  MyChinaRoots was the perfect intermediary.  I cannot speak highly enough
          of them. The research they offer is professional and comprehensive. The final report is amazing.
        </p>
      </div>
    </div>
    <prospect-request-end-actions>Find your ancestral home.</prospect-request-end-actions>
  </div>
</template>

<script>
import McrButtonRouterLinkToPr from '@common/elements/buttons/mcrButtonRouterLinkToPr';
import mcrButtonsRow from '@common/elements/buttons/mcrButtonsRow';

import ProspectRequestEndActions from '@/components/common/prospectRequestEndActions';

const mcrWikiGlossaryLink = () => import('@common/elements/glossary/mcrWikiGlossaryLink');

export default {
  metaInfo: {
    title: 'Village Location Package',
    meta: [{vmid: 'description', name: 'description', content: 'We track down the hometown of your Chinese ancestor.'}],
  },
  components: {
    McrButtonRouterLinkToPr,
    mcrWikiGlossaryLink,
    ProspectRequestEndActions,
    mcrButtonsRow,
  },
};
</script>

<style scoped lang="scss">
@import '~@/components/common/styles/services.scss';
@import '@common/style/mixins';
.researcher-image {
  margin-top: 24px;
  display: block;
}

.demo-report {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0;
  @media only screen and (max-width: $breakpoint-mobile) {
    .auto-video {
      margin-bottom: 12px;
    }
  }
}

img {
  box-shadow: $box-shadow;
}

.sample-report-image {
  margin-top: 18px;
  margin-bottom: 42px;
  border-bottom: 1px solid $divider-line-light;
  padding-bottom: 32px;
}
</style>
