<template>
  <div class="film-page">
    <xmas-banner v-if="$store.getters.featureSwitchesState.XMAS_DISCOUNT"></xmas-banner>
    <cny-banner v-if="$store.getters.featureSwitchesState.HOLIDAY24_SALE"></cny-banner>
    <header class="text_only">
      <h1>Capture Your Homecoming</h1>
      <div class="video" @click="onVideoClick">
        <lazy-youtube-video src="https://www.youtube.com/embed/aIVwv_1HHhU" />
      </div>
      <div class="header_actions">
        <mcr-buttons-row>
          <mcr-button-router-link-to-pr toStep="email" label="Get a Quote"></mcr-button-router-link-to-pr>
        </mcr-buttons-row>
      </div>
    </header>
    <div class="readable_content">
      <p>
        Returning to your ancestral village in China? Bring along our professional film crew to capture your experience
        with a bespoke <strong>legacy film</strong>.
      </p>
      <p>
        We record your memories so you can completely focus on the present. Slow down and soak up every moment as you
        reunite with long-lost relatives, walk in your ancestors' footsteps, and discover a side of yourself that you’ve
        never known before. We document your return in real-time and create beautiful cinematic videos that help you
        relive your roots trip for years to come.
      </p>

      <image-caption :imgSource="$getAsset('/assets/services/film/film1.jpg')" />
      <h4>How It Works</h4>

      <div class="bold">Share Your Travel Plans</div>
      <p>
        Tell us details about your upcoming roots trip. As planning gets underway, we will discuss ways to document your
        homecoming and draft you a quote suited to your needs.
      </p>

      <div class="bold">Lights, Camera, Action!</div>
      <p>
        We hit the road home with you, discreetly filming candid moments and precious discoveries of your trip as it
        unfolds.
      </p>

      <div class="bold">Relive Your Roots</div>
      <p>
        We weave your footage into a film authentic to your experience, allowing you to travel back and share your
        memories anytime, anywhere.
      </p>

      <h4>What to Expect</h4>
      <ul>
        <li><strong>Duration:</strong> Production typically lasts 2-4 months</li>
        <li><strong>Support:</strong> Dedicated MCR Story Consultant</li>
        <li><strong>Hosting:</strong> Private download link</li>
        <li><strong>Pricing:</strong> Determined on a case-by-case basis</li>
      </ul>
    </div>
    <prospect-request-end-actions>Get in touch to capture your journey.</prospect-request-end-actions>
  </div>
</template>

<script>
import McrButtonRouterLinkToPr from '@common/elements/buttons/mcrButtonRouterLinkToPr';
import mcrButtonsRow from '@common/elements/buttons/mcrButtonsRow';
import ImageCaption from '@common/elements/image-caption';
import AnalyticsHandler from '@common/utils/analytics/analytics.handler';
import AnalyticsMainHandler from '@common/utils/analytics/analytics.main';
import {getRoutePageName} from '@common/utils/analytics/utils.analytics';
import LazyYoutubeVideo from 'vue-lazy-youtube-video';

import cnyBanner from '@/components/common/promotions/cnyBanner';
import xmasBanner from '@/components/common/promotions/xmasBanner';
import ProspectRequestEndActions from '@/components/common/prospectRequestEndActions';

export default {
  metaInfo: {
    title: 'Film Services',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content:
          'Our professional film crew will capture your roots trip in a personal mini-documentary. Immortalize your trip today!',
      },
    ],
  },
  methods: {
    onVideoClick() {
      AnalyticsHandler.trackPlayVideoEvent('Film page');
      AnalyticsMainHandler.trackPlayVideoEvent('Film page', getRoutePageName(this.$route));
    },
  },
  components: {
    xmasBanner,
    cnyBanner,
    ProspectRequestEndActions,
    ImageCaption,
    McrButtonRouterLinkToPr,
    mcrButtonsRow,
    LazyYoutubeVideo,
  },
};
</script>

<style scoped lang="scss">
header {
  .video {
    box-shadow: $box-shadow;
    margin-bottom: 10px;
  }
}
.readable_content {
  .bold {
    margin-top: 30px;
  }
}
</style>
