<template>
  <div class="xmas-flag-link">
    <div class="xmas-flag">
      <img :src="this.$getAsset('/assets/promos/snowflake-left.png')" />
      <div class="sale-info">
        <span>{{ firstLabel }}</span>
        <span class="sale-title">
          <img :src="this.$getAsset('/assets/promos/snowflake-left.png')" />
          {{ title }}
          <img :src="this.$getAsset('/assets/promos/snowflake-right.png')" />
        </span>
        <span>{{ secondLabel }}</span>
      </div>
      <img :src="this.$getAsset('/assets/promos/snowflake-right.png')" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    firstLabel: {type: String, default: ''},
    secondLabel: {type: String, default: ''},
    title: {type: String, default: ''},
  },
};
</script>

<style scoped lang="scss">
/* Xmas Promo Banner */
.xmas-flag-link {
  width: 100%;
  &:hover {
    text-decoration: none;
  }
  box-shadow: $box-shadow;
  position: relative;
  z-index: 8;
}
.xmas-flag {
  background-color: $power-red;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 8px 0;
  .sale-info {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 24px;
    span {
      opacity: 0.8;
      text-shadow: 2px 2px 5px rgba(black, 0.5);
      &.sale-title {
        font-weight: bold;
        font-size: 1.4em;
        text-transform: uppercase;
        margin: 0 28px;
        opacity: 1;
        img {
          display: none;
        }
      }
    }
    @media only screen and (max-width: $breakpoint-tablet) {
      flex-direction: column;
      text-align: center;
      margin: 12px 24px;
      span {
        &.sale-title {
          margin: 8px 0;
        }
      }
    }
    @media only screen and (max-width: $breakpoint-phablet) {
      margin: 12px 0;
      span {
        &.sale-title {
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            display: block;
            width: 15%;
            margin: 0 8px;
          }
        }
      }
    }
  }
  > img {
    height: 60px;
    @media only screen and (max-width: $breakpoint-phablet) {
      display: none;
    }
  }
}
</style>
