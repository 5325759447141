<template>
  <div class="services-main-page info_page">
    <services-main-menu :class="menuClasses"></services-main-menu>
    <div class="info_section site_cover">
      <header class="background_header">
        <div class="research-header header-block">
          <img class="splash-bg" :src="$getAsset('/assets/services/site_cover.jpg')" />
          <div class="splash-overlay"></div>
          <div class="header-content">
            <h1 v-if="mainTitle">{{ mainTitle }}</h1>
            <div class="main-cta-buttons">
              <mcr-button-router-link-to-pr class="fat" label="Get in Touch"></mcr-button-router-link-to-pr>
              <book-call-pr-button class="fat"></book-call-pr-button>
            </div>
          </div>
        </div>
      </header>
    </div>

    <xmas-sale-flag v-if="$store.getters.featureSwitchesState.XMAS_DISCOUNT" title="Get 20% off this holiday!" />
    <cny-sale-flag v-if="$store.getters.featureSwitchesState.HOLIDAY24_SALE" title="GET 15% OFF THIS HOLIDAY SEASON!" />

    <div class="readable_content found-video-block">
      <p class="title heading-4">As featured in the Netflix documentary, <i>Found (2021)</i></p>
      <lazy-youtube-video :src="foundYoutubeSrc"></lazy-youtube-video>
      <p>
        Our China-based experts have 10+ years experience helping people of Chinese ancestry discover their origins.
      </p>
    </div>

    <div class="readable_content">
      <h4>Our services</h4>
      <div class="image_panel reverse_panel" :id="fieldResearchPanelId">
        <div class="image_panel_text">
          <h4>Field Research in China</h4>
          <div class="text-block">
            <div>
              Can’t travel to China? Our native Chinese researchers will go on your behalf to track down records,
              relatives, and more.
            </div>
          </div>
          <div>See: <router-link :to="{name: 'village-exploration-service'}">Ancestry Research</router-link></div>
        </div>
        <div class="image_panel_image">
          <img
            :data-src="$getAsset('/assets/services/ancestry/IMG_1835web.jpg')"
            class="lazyload"
            width="400"
            height="300"
          />
        </div>
      </div>

      <div class="image_panel">
        <div class="image_panel_text">
          <h4>Ancestry Investigation</h4>
          <div class="text-block">
            <div>
              From making phone calls, posting newspaper ads, to consulting online archival databases, our experts know
              how to identify the records and institutions most relevant to your family history.
            </div>
          </div>
          <div>
            See:
            <router-link :to="{name: 'village-location-service'}">Village Location Service</router-link>
          </div>
        </div>
        <div class="image_panel_image">
          <img
            :data-src="$getAsset('/assets/services/village/researchers-at-work.jpg')"
            class="lazyload"
            width="400"
            height="300"
          />
        </div>
      </div>

      <div class="image_panel reverse_panel">
        <div class="image_panel_text">
          <h4>Preserving & Storytelling</h4>
          <div class="text-block">
            <div>
              From films, websites, to online family trees, we make it easy for you to preserve your discoveries, and
              share them with the whole family.
            </div>
          </div>
          <div>
            See: <router-link :to="{name: 'storytelling'}">Storytelling</router-link>,
            <router-link :to="{name: 'village-exploration-service'}">Ancestry Research</router-link>,
            <router-link :to="{name: 'film'}">Homecoming Film</router-link>
          </div>
        </div>
        <div class="image_panel_image">
          <img
            :data-src="$getAsset('/assets/services/village/exploration-in-action.jpg')"
            class="lazyload"
            width="400"
            height="300"
          />
        </div>
      </div>

      <div class="image_panel">
        <div class="image_panel_text">
          <h4>Roots Trips to China</h4>
          <div class="text-block">
            <div>
              Nothing compares to a trip to your ancestral home. We help coordinate with your China relatives, design
              itineraries, and provide experienced multilingual guides to bridge any cultural or linguistic gaps.
            </div>
          </div>
          <div>
            See: <router-link :to="{name: 'travel'}">Travel</router-link>,
            <router-link :to="{name: 'village-exploration-service'}">Village Exploration Package</router-link>
          </div>
        </div>
        <div class="image_panel_image">
          <img :data-src="$getAsset('/assets/services/travel/travel2.jpg')" class="lazyload" width="400" height="300" />
        </div>
      </div>

      <div class="image_panel reverse_panel" :id="adoptionPanelId">
        <div class="image_panel_text">
          <h4>Adoption Research in China</h4>
          <div class="text-block">
            <div>
              We help adoptees navigate the challenges in tracing their birth parents by posting search ads, contacting
              orphanages, and advising on available DNA services.
            </div>
          </div>
          <div>See: <router-link :to="{name: 'adoption'}">Adoption Research</router-link></div>
        </div>
        <div class="image_panel_image">
          <img
            :data-src="$getAsset('/assets/services/adoption/newsad.jpg')"
            class="lazyload"
            width="400"
            height="300"
          />
        </div>
      </div>

      <div class="image_panel" :id="translationPanelId">
        <div class="image_panel_text">
          <h4>Translation Services</h4>
          <div class="text-block">
            <div>
              Can’t read Chinese? Our in-house translators specialize in translating Chinese family heirlooms and clues.
            </div>
          </div>
          <div>
            See: <router-link :to="{name: 'translation'}">Translation Services</router-link>,
            <router-link :to="{name: 'zupu-translation-services'}">Zupu Translation Services</router-link>,
            <router-link :to="{name: 'name-translation'}">Name Translation Service</router-link>
          </div>
        </div>
        <div class="image_panel_image">
          <img
            :data-src="$getAsset('/assets/services/translation/Knolling-Records-web-2.jpg')"
            class="lazyload in-layer"
            width="400"
            height="300"
          />
        </div>
      </div>
    </div>

    <div class="limited_content testimonials">
      <contact-testimonial />
      <strong>
        <router-link to="/testimonials/" class="read-more">View more testimonials &#10142;</router-link>
      </strong>
    </div>

    <div class="readable_content">
      <h4>How it Works</h4>
      <div class="pricing">
        <div class="figures-highlight">
          <div class="breakdown-property">
            <span class="price_label">Starting price</span>
            <span class="price">US$ 650</span>
          </div>
          <div class="breakdown-property">
            <span class="price_label">Average project length</span>
            <span class="price">1-3 Months</span>
          </div>
        </div>

        <div class="process-walkthrough">
          <div class="label">Step-by-step</div>
          <ul>
            <li>
              Start a <strong class="feature-highlight">(free) conversation</strong> with us about your goals and what
              you know so far.
            </li>
            <li>We'll give you some advice&mdash; for free and with no obligations!</li>
            <li>If you are interested, we'll send you a proposal according to your budget and goals.</li>
            <li>
              You'll also get to meet your <strong class="feature-highlight">paired researcher</strong>, who will be
              your personal agent in China.
            </li>
            <li>
              Once the project has started, you'll receive <strong class="feature-highlight">timely updates</strong>
              throughout your project from your researcher.
            </li>
            <li>Once complete, we will review our findings together and answer any questions you might have.</li>
          </ul>
        </div>
      </div>
    </div>

    <div class="limited_content demo-report">
      <h5>Receive a beautifully crafted digital report&mdash;perfect for sharing with the whole family.</h5>
      <div class="auto-video">
        <router-link to="/report/demo/baisha" target="_blank">
          <video
            loop
            muted
            autoplay
            :poster="$getAsset('/assets/services/ancestry/FWSBaishaDemo.jpg')"
            width="1200"
            height="666"
          >
            <source
              :src="this.$getAsset('/assets/services/ancestry/family-website-demo-baisha.mp4')"
              type="video/mp4"
            />
            <source
              :src="this.$getAsset('/assets/services/ancestry/family-website-demo-baisha.webm')"
              type="video/webm"
            />
          </video>
        </router-link>
      </div>
      <strong>
        <router-link to="/report/demo/baisha" class="demo-link" target="_blank"
          >Explore our live demo &#10142;
        </router-link>
      </strong>
    </div>

    <div class="readable_content">
      <h4>Working with My China Roots</h4>
      <p>
        Finding your family history does not happen overnight. For many, it is a lifelong experience that can be highly
        rewarding, exhilarating, and personal.
      </p>
      <p>
        Even if we are only joining you for part of the ride, we do not take the task of conducting your search lightly!
        As family historians, we are here to support you every step of the way, celebrate your stories as they come to
        light, and do it all at your own pace.
      </p>

      <p>
        <strong>
          <router-link :to="{name: 'our-team'}">Meet your future research partners &#10142;</router-link>
        </strong>
      </p>
      <div class="team">
        <team-member
          v-for="(member, index) in researchMembers"
          :key="index"
          :member="member"
          @click-read-more="handleClickReadMore"
        ></team-member>
      </div>
    </div>

    <videos-section></videos-section>

    <prospect-request-end-actions>Get in touch to trace your family history today.</prospect-request-end-actions>
  </div>
</template>

<script>
import McrButtonRouterLinkToPr from '@common/elements/buttons/mcrButtonRouterLinkToPr';
import {setHeaderTransparent} from '@common/elements/main-menu/transparentHeader';
import LazyYoutubeVideo from 'vue-lazy-youtube-video';
import {mapGetters} from 'vuex';

import BookCallPrButton from '@/base/elements/buttons/BookCallPrButton';
import ServicesMainMenu from '@/base/elements/main-menu/ServicesMainMenu';

import ContactTestimonial from '@/components/common/ContactUsTestimonial';
import cnySaleFlag from '@/components/common/promotions/cnySaleFlag';
import xmasSaleFlag from '@/components/common/promotions/xmasSaleFlag';
import ProspectRequestEndActions from '@/components/common/prospectRequestEndActions';

import TeamMemberDetails from '@/components/modules/aboutUs/team/team-member-details';
import TeamMember from '@/components/modules/aboutUs/team/team.member';
import VideosSection from '@/components/modules/main/components/VideosSection';

export default {
  metaInfo: {
    title: 'Our Services',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content:
          'Work with our China-based team of professional genealogists and translators to overcome the brick walls in your family history research.',
      },
    ],
  },
  mounted() {
    window.addEventListener('scroll', this.handleHeaderTransparent);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleHeaderTransparent);
  },
  data() {
    return {
      menuClasses: setHeaderTransparent() ? 'is-transparent' : '',
      fieldResearchPanelId: 'field-research-panel',
      adoptionPanelId: 'adoption-panel',
      translationPanelId: 'translation-panel',
      mainTitle: 'Work with Professional Genealogists in China',
      foundYoutubeSrc: 'https://www.youtube.com/embed/r7i_E6zm8lM',
    };
  },
  computed: {
    ...mapGetters(['featureSwitchesState']),
    researchMembers() {
      return this.$store.getters.researchMembersState;
    },
    modalParams() {
      let width = this.isDesktop ? '800' : '100%';
      let height = 'auto';
      let classes = 'clear_modal';
      return {width, height, classes, scrollable: true};
    },
    isDesktop() {
      return this.$store.getters.windowWidthState > this.$breakpoints.mobile;
    },
  },
  methods: {
    handleClickReadMore(member) {
      this.$modal.show(TeamMemberDetails, {member}, this.modalParams);
    },
    handleHeaderTransparent() {
      this.menuClasses = setHeaderTransparent() ? 'is-transparent' : '';
    },
  },
  components: {
    VideosSection,
    xmasSaleFlag,
    cnySaleFlag,
    ServicesMainMenu,
    McrButtonRouterLinkToPr,
    BookCallPrButton,
    ContactTestimonial,
    TeamMember,
    ProspectRequestEndActions,
    LazyYoutubeVideo,
  },
};
</script>

<style lang="scss" scoped>
@import '@common/style/mixins';
@import '@common/style/info.page';
@import '~@/components/common/styles/services.scss';

.services-main-page {
  display: block;
  header {
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    min-height: calc(100vh - 200px);
    height: auto;
    padding: 0;
    width: 100%;

    .header-block {
      z-index: 3;
      flex-basis: 0;
      flex-grow: 1;
      position: relative;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .header-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 3;
        padding: 0 $desktop-content-padding 0;
        min-height: 250px;

        .main-cta-buttons {
          margin-top: 40px;
          display: flex;

          .mcr-button:first-child {
            margin-right: 50px;
          }
        }
      }

      .splash-bg {
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        position: absolute;
        object-fit: cover;
        z-index: 1;
      }

      .splash-overlay {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(black, 0.4);
        z-index: 2;
      }
    }

    h1 {
      font-size: 42px;

      span {
        display: inline-block;
      }
    }

    @media only screen and (max-width: $breakpoint-desktop) {
      h1 {
        font-size: 38px;
      }
    }

    @media only screen and (max-width: $breakpoint-tablet) {
      min-height: calc(100vh - 150px);
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .header-block {
        flex-basis: auto;
        height: auto;
        width: 100%;

        .header-content {
          margin-top: 0;
        }
      }
    }

    @media only screen and (max-width: $breakpoint-mobile) {
      .header-block {
        .header-content {
          padding: $main-menu-height-mobile 70px 0;

          .main-cta-buttons {
            flex-direction: column;
            width: 100%;

            .mcr-button {
              margin-right: 0;
              margin-bottom: 20px;
              width: 100%;
            }
          }
        }
      }
    }

    @media only screen and (max-width: $breakpoint-phablet) {
      h1 {
        font-size: 36px;
      }
      .header-block {
        .header-content {
          padding: $main-menu-height-mobile 50px 0;
        }
      }
    }
    @media only screen and (max-width: $breakpoint-mobile-se) {
      .header-block {
        .header-content {
          padding: $main-menu-height-mobile $mobile-se-content-padding 0;
          justify-content: flex-start;
        }
      }
      h1 {
        font-size: 32px;
      }
    }

    @media only screen and (max-width: 350px) {
      h1 {
        font-size: 30px;
      }
      p {
        font-size: 18px;
      }
    }
  }

  .videos-section {
    padding-top: 0;
  }

  .image_panel {
    padding: 20px 0 0;
    @media only screen and (min-width: $breakpoint-tablet) {
      padding: 20px 0 40px;

      .image_panel_image img {
        width: 400px;
        height: 300px;
        object-fit: cover;
      }
    }
  }

  .found-video-block {
    padding-bottom: 0;
    .title {
      margin-bottom: 16px;
      text-align: center;
    }
    @media only screen and (min-width: $breakpoint-tablet) {
      width: 100%;
    }
  }

  .testimonials {
    text-align: center;
    background-color: #e0dcd8;
    margin-top: 24px;
    margin-bottom: 24px;

    .contact-testimonial {
      margin-top: 28px;
    }
    .read-more {
      display: block;
      margin-bottom: 24px;
    }
  }

  .demo-report {
    display: flex;
    flex-direction: column;
    align-items: center;
    .auto-video {
      margin-top: 32px;
    }
    @media only screen and (max-width: $breakpoint-mobile) {
      .demo-link {
        display: none;
      }
    }
  }

  .team {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    margin-top: 24px;
    &::after {
      content: '';
      flex: auto;
    }

    @media only screen and (max-width: $breakpoint-phablet) {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 0px;
    }
  }
}
</style>
